import Dashboard from "./components/Dashboard";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminLogin from "./components/AdminLogin";

function App() {
  const [adminLogin, setAdminLogin] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (adminLogin) {
      navigate("/dashboard");
    }
  }, [navigate, adminLogin]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AdminLogin
            loginStatus={(status) => {
              setAdminLogin(status);
              console.log(status);
            }}
          />
        }
      />
      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
  );
}

export default App;
