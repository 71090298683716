import React, { useState } from "react";
import "./styles/Dashboard.css";
import { FaFileUpload } from "react-icons/fa";
import { RiVideoUploadFill } from "react-icons/ri";
import UploadingModel from "../utils/UploadingModel";
import UploadForm from "../utils/UploadForm";

function Dashboard() {
  const [uploadQueue, setUploadQueue] = useState([]); // Use useState for uploadQueue
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleFileChange = (data) => {
    setUploadQueue((prevQueue) => [...prevQueue, data]); // Add the file data to the queue
  };

  return (
    <div className="dashboard">
      <div className="dashboard-menu">
        <div
          className="menu-item"
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          <span>
            <FaFileUpload /> Upload Lecture
          </span>
        </div>
        <div className="menu-item">
          <span>
            <RiVideoUploadFill /> Upload Notes
          </span>
        </div>
      </div>
      <div className="dashboard-content">
        <div className="header"></div>
        <UploadForm
          open={dialogOpen}
          onClose={(status) => {
            setDialogOpen(status);
          }}
          data={(data) => {
            handleFileChange(data);
          }}
        />
        {uploadQueue.map((upload, index) => (
          <UploadingModel key={index} upload={upload} />
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
