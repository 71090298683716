import React, {  useState } from "react";
import "./styles/AdminLogin.css";

export default function AdminLogin({ loginStatus }) {
  const [status, setStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (event) => {
    event.preventDefault();
    if (!status) {
      // Access the form data from the state
      console.log("Email:", email);
      console.log("Password:", password);
      if (email === "shivmouryacse@gmail.com" && password === "Kall@123") {
        loginStatus(true);
      }
    } else {
      loginStatus(status);
    }
  };

  return (
    <div className="admin-page-wrapper">
      <h1>Admin Login</h1>
      <form className="admin-form" onSubmit={handleLogin}>
        <input
          type="email"
          name="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}
