import React, { useState } from "react";
import "./styles/UploadForm.css";
import { GiCrossedBones } from "react-icons/gi";

export default function UploadForm({ open, onClose, data }) {
  const [Id, setId] = useState("");
  const [Index, setIndex] = useState("");
  const [file, setFile] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    let id = parseInt(Id);
    let index = parseInt(Index);
    data({ file, id, index });
    onClose(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <div className={`uploading-form-wrapper ${open ? "dialog-open" : ""}`}>
      <div className="cross-close">
        <GiCrossedBones
          style={{ cursor: "pointer" }}
          onClick={() => {
            onClose(false);
          }}
        />
      </div>
      <form className="lecture-form" onSubmit={handleSubmit}>
        <input
          type="number"
          required
          placeholder="Enter Course Id"
          name="courseId"
          value={Id}
          onChange={(e) => setId(e.target.value)}
        />
        <input
          required
          type="number"
          placeholder="Enter Lecture Index"
          name="index"
          value={Index}
          onChange={(e) => setIndex(e.target.value)}
        />
        <input
          type="file"
          placeholder="Select Lecture"
          onChange={handleFileChange}
          required
        />
        <button>Upload</button>
      </form>
    </div>
  );
}
