import React, { useEffect, useState, useRef } from "react";
import "./styles/UploadingModel.css";
import axios from "axios";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import { GiCrossedBones } from "react-icons/gi";
import { IoReload } from "react-icons/io5";

function UploadingModel({ upload }) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [isUploading, setIsUploading] = useState(true);
  const [uploaded, setUploaded] = useState(false);
  const cancelTokenSource = useRef(axios.CancelToken.source());

  useEffect(() => {
    let isMounted = true; // track if the component is still mounted

    const handleUpload = async () => {
      if (!uploaded) {
        const startTime = new Date().getTime();

        const formData = new FormData();
        formData.append("video", upload.file);
        formData.append("index", upload.index);
        formData.append("courseId", upload.id);

        // Log formData contents before sending
        for (let [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`);
        }

        try {
          await axios.post(
            "https://app.stenoexpert.in/streaming/uploadVideo",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Explicitly set Content-Type
              },
              onUploadProgress: (progressEvent) => {
                if (!isMounted) return; // Check if component is still mounted before updating state

                const { loaded, total } = progressEvent;
                const percentage = Math.floor((loaded * 100) / total);
                const currentTime = new Date().getTime();
                const elapsedTime = (currentTime - startTime) / 1000;
                const speed = (loaded / 1024 / elapsedTime).toFixed(2);

                setUploadProgress(percentage);
                setUploadSpeed(speed);
              },
              cancelToken: cancelTokenSource.current.token,
            }
          );

          if (isMounted) {
            setIsUploading(false);
            setUploadSpeed(0);
            setUploaded(true);
          }
        } catch (error) {
          console.error("Upload failed:", error);

          if (axios.isCancel(error)) {
            console.log(`Upload canceled for file: ${upload.file.name}`);
          }

          if (isMounted) {
            setIsUploading(false);
            setUploaded(true);
          }
        }
      }
    };

    handleUpload();

    return () => {
      isMounted = false; // Set to false when component unmounts
    };
  }, [upload]); // Only run when the 'upload' prop changes

  const handleCancelUpload = () => {
    console.log(`Canceling upload for file: ${upload.file.name}`);
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Upload canceled by the user.");
    }
    setIsUploading(false);
  };

  const handleReload = () => {
    if (!uploaded) {
      console.log(`Reloading upload for file: ${upload.file.name}`);
      setIsUploading(true);
      setUploadProgress(0);
      setUploadSpeed(0);

      // Trigger upload again
      const startTime = new Date().getTime();
      const handleUpload = async () => {
        const formData = new FormData();
        formData.append("video", upload.file);

        try {
          await axios.post(
            "https://app.stenoexpert.in/streaming/uploadVideo",
            formData,
            {
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentage = Math.floor((loaded * 100) / total);
                const currentTime = new Date().getTime();
                const elapsedTime = (currentTime - startTime) / 1000;
                const speed = (loaded / 1024 / elapsedTime).toFixed(2);

                setUploadProgress(percentage);
                setUploadSpeed(speed);
              },
              cancelToken: cancelTokenSource.current.token,
            }
          );

          setIsUploading(false);
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log(`Upload canceled for file: ${upload.file.name}`);
          } else {
            console.error("Upload failed:", error);
          }

          setIsUploading(false);
        }
      };

      handleUpload();
    }
  };

  return (
    <div className="uploading-model">
      <div className="file-name">
        <span>{upload.file.name}</span>
      </div>
      <LinearProgressWithLabel value={uploadProgress} uploading={isUploading} />
      <div className="uploading-status">{`${Math.round(uploadProgress)}%`}</div>
      <div className="uploading-speed">
        <span>
          {uploadSpeed > 1024
            ? `${Math.floor(uploadSpeed / 1024)} MB/s`
            : `${Math.floor(uploadSpeed)} KB/s`}
        </span>
      </div>
      <div className="cancel-upload">
        {isUploading ? (
          <div className="tooltip-container">
            <GiCrossedBones onClick={handleCancelUpload} />
            <span className="tooltip-text">Cancel</span>
          </div>
        ) : (
          <div className="tooltip-container">
            <IoReload color="red" onClick={handleReload} />
            <span className="tooltip-text">Reload</span>
          </div>
        )}
      </div>
    </div>
  );
}

function LinearProgressWithLabel(props) {
  const { value } = props;
  return (
    <div className="progress-bar">
      {value > 0 ? (
        <LinearProgress variant="determinate" value={value} />
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

UploadingModel.propTypes = {
  upload: PropTypes.shape({
    file: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default UploadingModel;
